import React, { useEffect, useState } from 'react';
import '../home.css';
import useTitle from '../utils/useTitle';
import { Link } from 'react-router-dom';

import setMetaTag from '../utils/setMetaTag';

import BottomAd from '../components/BottomAd';

function Home() {
    const [searchQuery, setSearchQuery] = useState('');
    const setHtmlTitle = useTitle("개발자 유미");
    const [mainData, setMainData] = useState('');
    const [recentData, setRecentData] = useState([]);

    useEffect(() => {
        setMetaTag("description", "백엔드 개발자 유미 공식 커뮤니티 사이트");

        const doFetch = async () => {
            const response = await fetch(`https://www.devyummi.com/api/v1/list?collection=PageCollection&count=15`, {
                method: 'GET'
            });

            if (response.ok) {
                const result = await response.json();
                setRecentData(result.data);
            }
        }
        doFetch();
    }, []);

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        console.log(`Searching for: ${searchQuery}`);
    };

    return (
        <div className="home">
            <div className="search-container">
                <form onSubmit={handleSearch} className="search-form">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleInputChange}
                        className="search-input"
                        placeholder="검색 서비스 준비 중입니다."
                    />
                    <button type="submit" className="search-button">검색</button>
                </form>
            </div>
            <div style={{ height: 'auto', margin: '20px', padding: '15px', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                <div className="sectionBody">
                    <h2>문서 카테고리별 정리</h2>
                        <Link to="/page?id=66ae0648cceeb3578956bce2">문서 모음 바로가기</Link>
                        <p>위 주소를 통해 시리즈(재생목록) 별로 정리된 문서 목록을 확인하실 수 있습니다.</p>
                </div>

                <div className="sectionBody">
                    <h2>최신 내용 정리 Docs</h2>
                    <Link className="viewAll" to="/list">전체 보기</Link>
                    <hr />
                    {recentData.map((post, index) => (
                        <Link className='recent' to={`/page?id=${post._id}`}>{post.title}</Link>
                    ))}
                </div>
                <p><br></br></p>
                <BottomAd />
            </div>
        </div>
    );
}

export default Home;